import React, { useState, useEffect } from 'react'
import './Card.css'

export default function Card(props) {
   
    const url = props.url
    const currentVideo = props.currentVideo
    const [error, setError] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [thumbnail, setThumbnail] = useState();
    const [title, setTitle] = useState();
    const [iframe, setIframe] = useState();

    useEffect(() => {
        
        fetch(url)
       
          .then(res => res.json())
          .then(
            (result) => {
              setIsLoaded(true);
              setThumbnail(result.thumbnail_url);
              setTitle(result.title);
              setIframe(result.html)
            },
            
            (error) => {
              console.log(error)
              setIsLoaded(true);
              setError(error);
            }
          )
      }, [])

      const handleClick = () => {
        props.handleCurrentVideo(url)
     }
    

      const divStyle = {
        color: 'blue',
        backgroundImage: 'url(' + thumbnail + ')',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        width: '250px',
        height: '250px',
      };


    let displayingVideo = false
    if(currentVideo === url){
      displayingVideo = true
    }

    let card
    if(displayingVideo){
      card =  <div className="card__iframe" dangerouslySetInnerHTML={{ __html:iframe}} />
    } else {
      card = <div className="card__picture" id={title} style={divStyle} onClick={() => handleClick()}>
                {/* <h3 className="card__title">{title}</h3> */}
              </div> 

    }


    return (
      
        <div className="card__button">
          {isLoaded && card}
        </div>
    )
}
