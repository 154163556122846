import React, { Component } from 'react'
import './Home.css'


export default class Home extends Component {
   
    render() {

        const facebookLogo = {
            backgroundImage: 'url(' + require('../img/facebook.png').default + ')'
        } 

        const youtubeLogo = {
            backgroundImage: 'url(' + require('../img/youtube.png').default + ')'
        } 

        const spotifyLogo = {
            backgroundImage: 'url(' + require('../img/spotify.png').default + ')'
        } 

        const instagramLogo = {
            backgroundImage: 'url(' + require('../img/instagram.png').default + ')'
        }

        const beatstarLogo = {
            backgroundImage: 'url(' + require('../img/beatstar.png').default + ')'
        }

        return (
            <div id="home" className="mx-2 rounded border-2 home__container max-w-7xl mx-auto sm:px-2 2xl:px-0">
                <h1 className="font__nice pb-0">Maté beats</h1>
                <h2 className="font__nice text-5xl pb-5">à la prod</h2>
                
                <div className="home__image">
                  

                </div>
                <div className='text-2xl pt-5 font__oswald pl-3 pr-3'>

                    <p>Je suis Maté, architecte sonore avec une plume ancrée dans l'univers du beatmaking depuis mes premiers pas dans le monde de la musique.</p>
                    <p>Ma passion pour le hip-hop, qui a germé dès les bancs de la maternelle, ne s'est jamais arrêtée.</p>

                    <p>Je propose des instrus essentiellement boombap tout en y incorporant des sonorités modernes (Trap, pop, etc). </p>
                    <p>Rejoignez-moi dans cette aventure musicale. Ensemble, explorons les infinités du son. Peace ✌️.</p>
                </div>
                <div className="flex justify-between items-center max-w-xs md:max-w-xl mx-auto pb-5 pt-7 px-2">
                    <a className="mx-2" href="https://www.youtube.com/channel/UC5WT4ozZ89Mwqo5rq0u0i_Q"><div className=" icon__social" style={youtubeLogo} /></a>
                    <a className="mx-2" href="https://www.facebook.com/mate.prod.5"><div className=" icon__social" style={facebookLogo} /></a>
                    <a className="mx-2" href="https://www.instagram.com/matebeats/"><div className=" icon__social" style={instagramLogo} /></a>
                    <a className="mx-2" href="https://open.spotify.com/artist/1INJIt6WFbipzIxICoQDb3"><div className=" icon__social" style={spotifyLogo} /></a>
                    <a className="mx-2" href="https://www.beatstars.com/djmat57"><div className=" icon__social" style={beatstarLogo} /></a>
                </div>
            </div>
        )
    }
}
