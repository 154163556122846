import React, { Component } from 'react'
import './Footer.css'

export default class Footer extends Component {

    render() {

        const facebookLogo = {
            backgroundImage: 'url(' + require('../img/facebook.png').default + ')'
        } 

        const youtubeLogo = {
            backgroundImage: 'url(' + require('../img/youtube.png').default + ')'
        } 

        const spotifyLogo = {
            backgroundImage: 'url(' + require('../img/spotify.png').default + ')'
        } 

        const instagramLogo = {
            backgroundImage: 'url(' + require('../img/instagram.png').default + ')'
        } 

        const beatstarLogo = {
            backgroundImage: 'url(' + require('../img/beatstar.png').default + ')'
        }

        return (
            <footer id="contact" className="bg-gray-800">
                <div className="max-w-7xl mx-auto py-5 px-5 flex items-center justify-between">
                    <h2 className="text-gray-50 text-4xl font__nice">Contact :</h2>
                    <a className="text-lg text-white xl:pl-5 font__nice text-4xl" href="mailto:matebeats57@gmail.com">matebeats57@gmail.com</a>
                </div>
                <div className="max-w-xs md:max-w-xl mx-auto py-5 px-5 flex items-center justify-between">
                    <a className="mx-2" href="https://www.youtube.com/channel/UC5WT4ozZ89Mwqo5rq0u0i_Q"><div className=" icon__social" style={youtubeLogo} /></a>
                    <a className="mx-2" href="https://www.facebook.com/mate.prod.5"><div className=" icon__social" style={facebookLogo} /></a>
                    <a className="mx-2" href="https://www.instagram.com/matebeats/"><div className=" icon__social" style={instagramLogo} /></a>
                    <a className="mx-2" href="https://open.spotify.com/artist/1INJIt6WFbipzIxICoQDb3"><div className=" icon__social" style={spotifyLogo} /></a>
                    <a className="mx-2" href="https://www.beatstars.com/djmat57"><div className=" icon__social" style={beatstarLogo} /></a>
                </div>
            </footer>
        )
    }
}


/*
 <footer className="bg-gray-800">
                <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
                    <div className="relative flex items-center justify-between h-16">
                       
                            <img src={require('../img/bg.jpg').default} />
                        
                        <div className="sm:ml-6">
                            <div className="flex space-x-4">
                            <h1 className="text-gray-50 text-3xl">Maté Beats</h1>
                                <div className="flex justify-around items-center">
                                    <a className="mx-2" href="https://facebook.com"><img className="h-8" src={require('../img/youtube.png').default} /></a>
                                    <a className="mx-2" href="https://facebook.com"><img className="h-8" src={require('../img/facebook.png').default} /></a>
                                    <a className="mx-2" href="https://facebook.com"><img className="h-8" src={require('../img/instagram.png').default} /></a>
                                    <a className="mx-2" href="https://facebook.com"><img className="h-8" src={require('../img/spotify.png').default} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            */