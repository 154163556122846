
import React, { Component } from 'react'
import Card from './components/Card'
import './App.css'
import Home from './components/Home'
import Nav from './components/Nav'
import Footer from './components/Footer'

export default class App extends Component {

  state = {
    currentVideo:'',
    urls: [
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Wq-iu--lB4w&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=L4V-0vmT9zA&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=9uV_W-CfZs4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=GbwUSS_Dtkc&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=I9A8NACrXPA&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=0pUyZfaBZyI&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=NIsQXnYnpnw&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=8wRRYrF319A&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=EWDoAy7yv5w&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=UxdpcnPeIpQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=00yXGfiv0kQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=SFODOcXEOqU&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=xOQz3PLAUJ0&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=PUtV20SIvhg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=S4sUGT4I7SM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=FDdpA34EW2c&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=6Rh4wihVdOM&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=sFJg6swGNN4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Dv4V4OX5P80&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=5pNnVCHhYaU&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=82sUqIHYMuw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Wv0PlrSrtiY&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=KZWIeCpxDYY&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=ihtiO2Micpc&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Qsri98pCtTw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=VRonNm2KgJM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=9oEduzKyXSo&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=lyNznwaHa4w&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=12_gIRF-89o&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=hdflHQYWmsM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=XDEWDYfHvT4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=3fQ6NFPV4XM&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=bwLReKNm-n8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=AMEggQ6cris&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=oS4-_fXZZig&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=FtDLZ2L6_BY&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=NEBqPRWycow&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=VEaI3r4f6E8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=B-fgbeyvG2w&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=NsiWzWpaO7Q&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=WtumNO8ELQ8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=zhJtiqgc_XY&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=6zzjxHICduw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=OPGJJCgx3aA&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=CfbVJ0ph3zM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=qn368Ty58wI&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=SwYMAcFsMNs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=D6iiklIc13s&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=ump0BDuVkiQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Gas4jrXNmZ0&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=lCkStUScnhA&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=gnjs_eLQoKg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=t7zG0zGFOKg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=659IjfcMyuQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=7lziwik-7tU&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=d5_vciosLKE&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=DU6kZ2L70Dk&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=L0cZAVfMPeQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=C-psZy7cLLs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=ycrHekRM69M&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=uvI7P8j6Rcw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=JIT_WAc_FZ4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=sFAz4nu1P4U&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=f3N1lISE3Bs&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=p-6YQ8IwuSI&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=12EtpGS4bV0&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=6DnvsXARnMk&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=O1Qj3Q4eZr8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=NXKik_lPy_s&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=wKTGOOO6Ppo&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=1BOR8mBGdLM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=u6TLLauWc4U&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=s6pjr4qBJFQ&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=YKyZx7lFrY8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=VKw6f-eSg9o&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=9RCfXGtgIl8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=bskJdlvWDIg&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=eYraKkixsaA&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=j1M0EsRpO4E&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=IK16QBXd8DI&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=qSiiqAbgTgM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=pj3s85sXf5A&format=json',
      
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=ygtu1ldhanM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=-dDSHHzJjGs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=UjRDP8Dz9HU&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Kzn65Bt0jm0&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=CEqgGHXLb_E&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=26I9ySCDTRs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=tDRNQXtHHiw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=MRxX-BQIznI&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=cBmmfQ7Ltho&format=json',
    
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=3WFAGkgL4jM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=fiO1umX4w-Q&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=UIzEGTli3Ik&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=NKGICQWpCT8&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=sAR41IFqJZE&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=g1cfTRdjKcg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=fGix5sYyca8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=WNLn9zcUfZ4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=a0e0oeM1oUE&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=sZgAL1sfjIM&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=7R8E9IBr0ts&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=dKT0OLLYfO4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=F3jIvkJ95D8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=FVXwKPPdQGY&format=json',

      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Exd-d5m0TfI&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=iQ0swJGyLQs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=YeLaYPwwl04&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=-NIF3XhWXjQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=uqestyBWFH8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=rKZgxs5qi0k&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=pcRN5GuPYWg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=KYofSpzu0cw&format=json',
      
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=e_XuMRjIf0o&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=LlnhSDbta0w&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=S5eRba5iZdw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Y-arcfBaO5E&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=qA98i-9EwRo&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=DXe6PW5QRAg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=-kyWPJ1aIqg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=_e1KxTqPZTY&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=k6I4vEfKzfs&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=R6nkYIamMus&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=aZT9d5ef7BQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=7MBJNyVKYYc&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Y7zVm9E-Diw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=lH6bmmFYPEA&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=cjDougzc_sE&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=JMdigil-6oU&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=YEGluZ68fRU&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=HrbxhFRSrGQ&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=7iUfG5gWyIw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=uvKgTh-HlOw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=d6k2h4jtbkg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=Q_8B5MJ53lw&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=S2s4H4C6l18&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=HPkaV3aijw4&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=L1ordAe0QEE&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=9TGGDwGcR94&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=I_7LsfU0fpA&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=0EA0aUUmheE&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=uniP2nXLsXU&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=6tISnGmLwt8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=gJUhxzJgmz8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=u-NgA0ir3Dg&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=FV6LnpGtH6s&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=_6ANraxZTg0&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=CN6pbe0pSw8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=nJJpmdqdPf8&format=json',
      'https://www.youtube.com/oembed?url=https%3A//youtube.com/watch?v=6Vhvl2PW_10&format=json',
   
    ],
  }

  handleCurrentVideo = (currentVideo) => {
    this.setState({currentVideo})
  }

  render() {
    const cardsLine = Object.keys(this.state.urls).map(key => 
      <Card key={key} url={this.state.urls[key]} handleCurrentVideo={this.handleCurrentVideo} currentVideo={this.state.currentVideo} />
    )

    return (
      <main className="main__container bg-gray-200">
        <Nav />
        <Home />
        <h2 id="beats" className="title__beats mx-2 mb-5 max-w-7xl mx-auto text-center md:text-left sm:px-2 2xl:px-0">Last Beats :</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3 mx-2 my-2 max-w-7xl mx-auto px-2 sm:px-2 2xl:px-0 pb-3">
        {cardsLine}
        </div>
        <Footer />
      </main>
    )
  }
}
