import React, { Component } from 'react'


export default class Nav extends Component {

    state = {
        buttonActive: false
    }

    handleButton() {
        const buttonActive = !this.state.buttonActive
        this.setState({buttonActive})
    }

    closeMenu() {
        const buttonActive = false
        this.setState({buttonActive})
    }

    render() {
        const buttonActive = this.state.buttonActive
        return (
            <nav className="bg-gray-800 fixed w-full top-0 z-50">
                <div className="max-w-7xl mx-auto sm:px-2 2xl:px-0">
                    <div className="relative flex items-center justify-between h-16">
                        <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                            <button onClick={() => this.handleButton()} type="button" className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-controls="mobile-menu" aria-expanded="false">
                            <span className="sr-only">Open main menu</span>
                            <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                            <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                            </button>
                        </div>
                        <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                            {/*<h1 className="text-gray-50 text-3xl font__nice">Maté Beats</h1>*/}
                        </div>
                        <div className="hidden sm:block sm:ml-6">
                            <div className="flex space-x-4">
                                <a href="#home" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-3xl font__nice" aria-current="page">Home</a>
                                <a href="#beats" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 text-3xl py-2 rounded-md font__nice">Beats</a>
                                <a href="#contact" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 text-3xl rounded-md font__nice">Contact</a>
                            </div>
                        </div>
                    </div>
                </div>
                {buttonActive && 
                <div className="sm:hidden" id="mobile-menu">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        <a href="#home" onClick={() => this.closeMenu()} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-3xl text-center font__nice" aria-current="page">Home</a>
                        <a href="#beats" onClick={() => this.closeMenu()} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-3xl text-center font__nice" aria-current="page">Beats</a>
                        <a href="#contact" onClick={() => this.closeMenu()} className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-3xl text-center font__nice">Contact</a>
                    </div>
                </div>
                }
            </nav>
        )
    }
}
